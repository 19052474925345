<template>
  <button @click="goBack">
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="20" height="20" rx="3" fill="#EBEEF5" />
      <path
        d="M6.78213 9.2894C6.83289 9.23882 6.88795 9.19614 6.94589 9.15901L10.8216 5.28349C11.1997 4.90556 11.8131 4.90539 12.1914 5.28366C12.5697 5.66176 12.5697 6.27501 12.1914 6.65347L8.8536 9.99126L12.2086 13.3464C12.5869 13.7245 12.5869 14.3376 12.2086 14.7162C12.0194 14.9057 11.7713 15 11.5238 15C11.2763 15 11.028 14.9057 10.839 14.7162L6.94589 10.8228C6.88795 10.7857 6.83253 10.743 6.78213 10.6924C6.58877 10.499 6.4955 10.2443 6.50017 9.9909C6.4955 9.73746 6.58877 9.48294 6.78213 9.2894Z"
        :fill="UTIL_COLORS.DEFAULT"
      />
    </svg>
  </button>
</template>
<script setup lang="ts">
import { useRouter } from "vue-router";
import type { PropType } from "vue";
import type { RouteLocationRaw } from "vue-router";
import { UTIL_COLORS } from "@/helpers/constants";

const props = defineProps({
  // Can be a route name or path
  route: {
    type: [String, Object] as PropType<string | RouteLocationRaw>,
    default: ""
  }
});
const router = useRouter();

const goBack = () => {
  // hasRoute() checks for a route with a matching name. If a valid route name was passed, this will be true
  if (typeof props.route === "string" && router.hasRoute(props.route)) {
    router.push({ name: props.route });
    return;
  }

  router.push(props.route);
};
</script>
